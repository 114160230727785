import { FC } from 'react';
import { Link, To } from 'react-router-dom';

import arrowBack from './arrow-back.svg';

interface IProps {
  to: To;
  className?: string;
}

export const BackButton: FC<IProps> = ({ to, className }) => {
  return (
    <Link to={to} className={className}>
      <img alt='Back' src={arrowBack}></img>
    </Link>
  );
};
