import { Geometry } from 'geojson';
import { IPaginationRequest } from './pagination';
import { IZoneLookupValue } from './lookup-value';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAreaFilter extends IPaginationRequest {}

export enum AreaType {
  General = 'General',
  Study = 'Study',
  Rpp = 'Rpp',
}

export interface IAreaItem {
  Id: number;
  Type: AreaType;
  CdsId: string;
  Name: string;
  CreatedAt: Date;
  UpdatedAt: Date;
  Zones: IZoneLookupValue[];
}

export interface IArea {
  Id: number;
  Type: AreaType;
  CdsId: string;
  Name: string;
  CreatedAt: Date;
  UpdatedAt: Date;
  Geometry?: Geometry;
}
