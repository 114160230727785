import {
  AreaType,
  IPaginationRequest,
  IPaginationResponse,
  IZone,
  IMeter,
  ISpace,
  ICamera,
  IZoneFilter,
  IZoneItem,
  ZoneType,
} from '../../../model/api/assets';
import { getAssetsApi } from '../api';

const BASE_URL = '/zones';

const search = async (filter: IZoneFilter) => {
  const url = `${BASE_URL}?skip=${filter.skip}&take=${filter.take}`;
  const api = await getAssetsApi();
  return api
    .get<IPaginationResponse<IZoneItem>>(url)
    .then((response) => response.data)
    .then((res) => {
      res.Data.forEach((x) => {
        fixZoneDates(x);
      });
      return res;
    });
};

const getZone = async (id: number, type: ZoneType): Promise<IZone> => {
  const api = await getAssetsApi();
  return api
    .get<IZone>(`${BASE_URL}/${type}/${id}`)
    .then((response) => response.data)
    .then((x) => {
      fixZoneDates(x);
      return x;
    });
};

const getZoneMeters = async (zoneId: number, type: ZoneType): Promise<IMeter[]> => {
  if (type === ZoneType.Rpp) {
    return Promise.resolve([]);
  }

  const api = await getAssetsApi();
  return api
    .get<IMeter[]>(`${BASE_URL}/${zoneId}/meters`)
    .then((response) => response.data)
    .then((res) => {
      res.forEach((x) => {
        x.PublishedDate = new Date(x.PublishedDate);
        x.LastUpdatedDate = new Date(x.LastUpdatedDate);
      });
      return res;
    });
};

const getZoneSpaces = async (zoneId: number, type: ZoneType): Promise<ISpace[]> => {
  if (type === ZoneType.Rpp) {
    return Promise.resolve([]);
  }

  const api = await getAssetsApi();
  return api
    .get<ISpace[]>(`${BASE_URL}/${zoneId}/spaces`)
    .then((response) => response.data)
    .then((res) => {
      res.forEach((x) => {
        x.PublishedDate = new Date(x.PublishedDate);
        x.LastUpdatedDate = new Date(x.LastUpdatedDate);
      });
      return res;
    });
};

const getZoneCameras = async (zoneId: number, type: ZoneType): Promise<ICamera[]> => {
  if (type === ZoneType.Rpp) {
    return Promise.resolve([]);
  }

  const api = await getAssetsApi();
  return api
    .get<ICamera[]>(`${BASE_URL}/${zoneId}/cameras`)
    .then((response) => response.data)
    .then((res) => {
      res.forEach((x) => {
        x.PublishedDate = new Date(x.PublishedDate);
        x.LastUpdatedDate = new Date(x.LastUpdatedDate);
      });
      return res;
    });
};

const listByArea = async (areaId: number, type: AreaType, pagination: IPaginationRequest) => {
  const url = `${BASE_URL}/by-area?id=${areaId}&type=${type}&skip=${pagination.skip}&take=${pagination.take}`;
  const api = await getAssetsApi();
  return api
    .get<IPaginationResponse<IZoneItem>>(url)
    .then((response) => response.data)
    .then((res) => {
      res.Data.forEach((x) => {
        fixZoneDates(x);
      });
      return res;
    });
};

const fixZoneDates = (zoneItem: IZone | IZoneItem) => {
  zoneItem.PublishedDate = new Date(zoneItem.PublishedDate);
  zoneItem.LastUpdatedDate = new Date(zoneItem.LastUpdatedDate);
  zoneItem.StartDate = new Date(zoneItem.StartDate);
  if (zoneItem.EndDate) {
    zoneItem.EndDate = new Date(zoneItem.EndDate);
  }
};

export const zonesService = {
  search,
  getZone,
  getZoneMeters,
  getZoneSpaces,
  getZoneCameras,
  listByArea,
};
