import { FC } from 'react';

import { Spinner } from '../spinner/Spinner';

import styles from './PopupPlaceholder.module.scss';
import { PopupHeaderPlaceholder } from './PopupHeaderPlaceholder';
import { PopupBodyPlaceholder } from './PopupBodyPlaceholder';

interface Props {
  loading?: boolean | undefined;
  rows?: number;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const PopupPlaceholder: FC<Props> = ({ loading, rows, style, children }) => (
  <div className={`${styles.wrapper}`} style={style}>
    {children}
    {loading && (
      <div className={styles.placeholder}>
        <PopupHeaderPlaceholder />
        <div>
          <PopupBodyPlaceholder rows={rows} />
          <Spinner backdrop={true} />
        </div>
      </div>
    )}
  </div>
);
