import { IPaginationResponse, IArea, IAreaFilter, IAreaItem, AreaType } from '../../../model/api/assets';
import { getAssetsApi } from '../api';

const BASE_URL = '/areas';

const search = async (filter: IAreaFilter) => {
  const url = `${BASE_URL}?skip=${filter.skip}&take=${filter.take}`;
  const api = await getAssetsApi();
  return api
    .get<IPaginationResponse<IAreaItem>>(url)
    .then((response) => response.data)
    .then((res) => {
      res.Data.forEach((x) => {
        fixDates(x);
      });
      return res;
    });
};

const getArea = async (id: number, type: AreaType): Promise<IArea> => {
  const api = await getAssetsApi();
  return api
    .get<IArea>(`${BASE_URL}/${type}/${id}`)
    .then((response) => response.data)
    .then((x) => {
      fixDates(x);
      return x;
    });
};

const fixDates = (item: IArea | IAreaItem) => {
  item.CreatedAt = new Date(item.CreatedAt);
  item.UpdatedAt = new Date(item.UpdatedAt);
};

export const areasService = {
  search,
  getArea,
};
