import { lazy, ReactElement } from 'react';
import { Route } from 'react-router-dom';

const Zones = lazy(() => import('./zones/Zones'));
const Zone = lazy(() => import('./zones/Zone'));
const Areas = lazy(() => import('./areas/Areas'));
const Area = lazy(() => import('./areas/Area'));
const Policies = lazy(() => import('./policies/Policies'));
const Policy = lazy(() => import('./policies/Policy'));
const EditPolicy = lazy(() => import('./policies/EditPolicy'));

const zonesRoute = 'zones';
const areasRoute = 'areas';
const policiesRoute = 'policies';

export const createAssetsRoutes = (): ReactElement => (
  <>
    <Route path={zonesRoute}>
      <Route index element={<Zones />} />
      <Route path=':type/:id' element={<Zone />} />
    </Route>
    <Route path={areasRoute}>
      <Route index element={<Areas />} />
      <Route path=':type/:id' element={<Area />} />
    </Route>
    <Route path={policiesRoute}>
      <Route index element={<Policies />} />
      <Route path=':id'>
        <Route index element={<Policy />} />
        <Route path='edit' element={<EditPolicy />} />
      </Route>
    </Route>
  </>
);
