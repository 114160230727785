import { IEntityBase } from './entity-base';

import { Position } from 'geojson';

export enum SpotType {
  Regular = 'Regular',
  Loading = 'Loading',
}

export enum OccupancyStatus {
  Unknown = 'Unknown',
  Vacant = 'Vacant',
  Occupied = 'Occupied',
  Booked = 'Booked',
}

export enum DayOfWeekId {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export enum SpotActivityType {
  Parking = 'Parking',
  NoParking = 'NoParking',
  BusStop = 'BusStop',
}

export interface ISpot extends IEntityBase {
  Type: SpotType;
  Name: string;
  ZoneId: number | null;
  PolicyTypeId: number;
  Positions: Array<Position>;
  ParkingSensorId: number | null;
  DataSourceEntityId: string;
  BlockfaceId: number | null;
  HasCameras: boolean;
  HasSensors: boolean;
  HasTransactions: boolean;
  Width?: number;
  Length?: number;
  Order?: number;
  CreatedAt: Date;
  UpdatedAt: Date;
  CdsId: string;
}

export interface ISpotState {
  PolicyTypeId: number;
  Status: OccupancyStatus;
  SpotId: number;
  CompanyName?: string;
}

// Regulations

export interface ISpotRegulation {
  PolicyId: number;
  Schedules: Array<ISchedule>;
}

export interface ISchedule {
  Items: Array<IScheduleItem>;
  ScheduleTypeId: number;
}

export interface IScheduleItem {
  DayOfWeekId: DayOfWeekId;
  ToTime: string;
  FromTime: string;
  Rates: Array<IScheduleItemRate>;
  TimeLimitMinutes?: number;
}

export interface IScheduleItemRate {
  FromTime: string;
  Rate: number;
  IsRecurring: boolean;
  ToTime: string;
}

export interface IOccupancyStatusCount {
  Status: OccupancyStatus;
  Count: number;
}

export interface IActivityTypeCount {
  Type: SpotActivityType;
  Count: number;
}
