import { useEffect, useRef, useState } from 'react';

import { MultiSelect, MultiSelectChangeEvent } from '@progress/kendo-react-dropdowns';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Hint, Label, Error } from '@progress/kendo-react-labels';

export const FormMultiselect = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    optional,
    required,
    value,
    valueMap,
    parseValue,
    data,
    onChange,
    ...others
  } = fieldRenderProps;
  const editorRef = useRef(null);

  const showValidationMessage: string | false | null = touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : '';
  const errorId: string = showValidationMessage ? `${id}_error` : '';
  const labelId: string = label ? `${id}_label` : '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: MultiSelectChangeEvent) => {
    event.value = valueMap && event.value ? event.value.map((x: any) => valueMap(x)) : event.value;
    onChange && onChange(event);
  };

  const [valueItem, setValueItem] = useState();
  useEffect(() => {
    let newValue = value;

    if (valueMap && value) {
      newValue = value.map((x: any) => (data || []).find((i: any) => valueMap(i) === x));
    }

    if (parseValue) {
      newValue = parseValue(value);
    }

    setValueItem(newValue);
  }, [data, parseValue, value, valueMap]);

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        className={`${required ? 'required' : null}`}
      >
        {label}
      </Label>
      <div className={'k-form-field-wrap'}>
        <MultiSelect
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          data={data}
          {...others}
          value={valueItem}
          onChange={handleChange}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </div>
    </FieldWrapper>
  );
};
