import { FunctionComponent } from 'react';
import { Geometry } from 'geojson';

interface Props {
  className?: string;
  geometry?: Geometry;
}

export const GeometryText: FunctionComponent<Props> = ({ className, geometry }) => {
  function format(item: any): string {
    return item.toFixed(4);
  }

  if (!geometry) {
    return null;
  }

  if (geometry.type === 'Point') {
    return (
      <span className={`${className ?? ''}`}>
        <span>
          [{format(geometry.coordinates[0])}, {format(geometry.coordinates[1])}]
        </span>
      </span>
    );
  }

  if (geometry.type === 'Polygon') {
    return (
      <span className={`${className ?? ''}`}>
        {geometry.coordinates[0].map((x, idx) => (
          <span key={idx}>
            [{format(x[0])}, {format(x[1])}]
          </span>
        ))}
      </span>
    );
  }

  if (geometry.type === 'LineString') {
    return (
      <span className={`${className ?? ''}`}>
        {geometry.coordinates.map((x, idx) => (
          <span key={idx}>
            [{format(x[0])}, {format(x[1])}]
          </span>
        ))}
      </span>
    );
  }

  return null;
};
