import { useEffect, useRef, useState } from 'react';

import { ComboBox } from '@progress/kendo-react-dropdowns';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Error, Hint, Label } from '@progress/kendo-react-labels';

export const FormCombobox = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    optional,
    required,
    value,
    valueMap,
    data,
    dataItemKey,
    textField,
    onChange,
    ...others
  } = fieldRenderProps;
  const editorRef = useRef(null);

  const [dataItems, setDataItems] = useState<any[]>([]);

  const showValidationMessage: string | false | null = touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : '';
  const errorId: string = showValidationMessage ? `${id}_error` : '';
  const labelId: string = label ? `${id}_label` : '';

  useEffect(() => {
    setDataItems(data);
  }, [data]);

  useEffect(() => {
    if (value && dataItems.length > 0 && !findValueItem(value, dataItems, valueMap)) {
      data.push({
        [textField]: value,
        [dataItemKey]: value,
      });

      setDataItems([...data]);
    }
  }, [data, dataItemKey, dataItems, textField, value, valueMap]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any) => {
    if (dataItemKey && event.value && event.value[dataItemKey] === undefined) {
      event.value = {
        [textField]: event.value[textField],
        [dataItemKey]: event.value[textField],
      };

      dataItems.push(event.value);
      setDataItems([...dataItems]);
    }

    event.value = valueMap && event.value ? valueMap(event.value) : event.value;
    onChange && onChange(event);
  };

  const [valueItem, setValueItem] = useState();
  useEffect(() => {
    const newValueItem = findValueItem(value, dataItems, valueMap);
    setValueItem(newValueItem);
  }, [dataItemKey, dataItems, textField, value, valueMap]);

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        className={`${required ? 'required' : null}`}
      >
        {label}
      </Label>
      <div className={'k-form-field-wrap'}>
        <ComboBox
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          data={data}
          dataItemKey={dataItemKey}
          textField={textField}
          {...others}
          value={valueItem}
          onChange={handleChange}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </div>
    </FieldWrapper>
  );
};

function findValueItem(value: any, dataItems: any[], valueMap?: (x: any) => any) {
  const newValue = valueMap && value && typeof value === 'object' ? valueMap(value) : value;
  return valueMap && newValue ? dataItems.find((x: any) => valueMap(x) === newValue) : newValue;
}
