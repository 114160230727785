import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Label, Error, Hint } from '@progress/kendo-react-labels';

export const FormNumericTextBox = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, disabled, optional, required, hint, value, visited, modified, ...others } =
    fieldRenderProps;

  const showValidationMessage: string | false | null = touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : '';
  const errorId: string = showValidationMessage ? `${id}_error` : '';

  const val = value !== undefined ? value : null;

  return (
    <FieldWrapper>
      {label ? (
        <Label editorId={id} editorValid={valid} editorDisabled={disabled} className={`${required ? 'required' : null}`}>
          {label}
        </Label>
      ) : null}

      <div className='k-form-field-wrap'>
        <NumericTextBox ariaDescribedBy={`${hintId} ${errorId}`} valid={valid} id={id} disabled={disabled} value={val} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId} className='error'>
            {validationMessage}
          </Error>
        )}
      </div>
    </FieldWrapper>
  );
};
