import { lazy, ReactElement } from 'react';
import { createHashRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';

import AuthenticatedRoute from './components/auth/AuthenticatedRoute';
import AuthorizedRoute from './components/auth/AuthorizedRoute';
import Forecasting from './pages/forecasting/Forecasting';
import LoadingZones from './pages/loading-zones/LoadingZones';
import { createAssetsRoutes } from './pages/assets/create-assets-routes';
import { getAppSettings } from './services';
import { createMsalInstance, ssoSilent } from './features';

const NoAccess = lazy(() => import('./pages/no-access/NoAccess'));
const Map = lazy(() => import('./pages/map/Map'));
const Reports = lazy(() => import('./pages/reports/Reports'));
const Login = lazy(() => import('./pages/login/Login'));
const Assets = lazy(() => import('./pages/assets/Assets'));

const ReportsRoute = '/reports';
const ForecastRoute = '/forecast';

export const createAppRouter = (appElement: ReactElement) => {
  return createHashRouter(
    createRoutesFromElements(
      <Route
        element={appElement}
        loader={async ({ params }) =>
          getAppSettings()
            .then((settings) => createMsalInstance(settings))
            .then((msalInstance) => ssoSilent(msalInstance))
        }
      >
        <Route element={<AuthenticatedRoute requireAuth={false} redirectPath='/map' />}>
          <Route path='/login' element={<Login />} />

          <Route path='*' element={<Navigate to='/login' replace />} />
        </Route>

        <Route element={<AuthenticatedRoute requireAuth={true} redirectPath='/login' />}>
          <Route element={<AuthorizedRoute requireAuth={false} redirectPath='map' />}>
            <Route path='/no-access' element={<NoAccess />} />
            <Route path='*' element={<Navigate to='/no-access' replace />} />
          </Route>

          <Route element={<AuthorizedRoute requireAuth={true} redirectPath='/no-access' />}>
            <Route path='/map' element={<Map />} />
            <Route path={`${ReportsRoute}/*`} element={<Reports />} />
            <Route path={`${ForecastRoute}/*`} element={<Forecasting />} />
            <Route path='/loading-zones/*' element={<LoadingZones />} />
            <Route path='/assets/*' element={<Assets />}>
              {createAssetsRoutes()}
            </Route>

            <Route path='*' element={<Navigate to='/map' replace />} />
          </Route>
        </Route>
      </Route>,
    ),
  );
};
