import { IRule, TimeSpans } from '../policy';
import { ZoneType } from './zone';
import { IPaginationRequest } from './pagination';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPolicyFilter extends IPaginationRequest {}

export interface IPolicyItem {
  CurbPolicyId: string;
  Id: number;
  PublishedDate: Date;
  Priority: number;
  Rules: IRule[];
  TimeSpans: TimeSpans[] | null;
  DataSourceName: string;
  StartDate?: Date;
  EndDate?: Date;
  ZonesCount?: number;
}

export interface IPolicy {
  Id: number;
  CdsId: string;
  Name?: string;
  PublishedDate: Date;
  Priority: number;
  Rules: IRule[];
  TimeSpans: TimeSpans[] | null;
  Active: boolean;
  DataSourceName: string;
}

export interface IZonePolicy {
  ZoneId: number;
  TypeType: ZoneType;
  ZoneName: string;
  ZoneCdsId: string;
  StartDate: Date;
  EndDate?: Date;
  Active: boolean;
}

export interface IPolicyUpsert {
  Name?: string;
  PublishedDate: Date;
  Priority: number;
  Rules: IRule[];
  TimeSpans: TimeSpans[] | null;
  Active: boolean;
  DataSourceName?: string;
}

export function policyToUpsert(policy: IPolicy): IPolicyUpsert {
  return {
    Name: policy.Name,
    PublishedDate: policy.PublishedDate,
    Priority: policy.Priority,
    Rules: policy.Rules,
    TimeSpans: policy.TimeSpans,
    Active: policy.Active,
    DataSourceName: policy.DataSourceName,
  };
}
