import { IAreaLookupValue, ILookupValue } from './lookup-value';
import { Geometry } from 'geojson';
import { IPaginationRequest } from './pagination';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IZoneFilter extends IPaginationRequest {}

export enum ZoneType {
  Parking = 'Parking',
  Rpp = 'Rpp',
}

export interface IZoneItem {
  Id: number;
  Type: ZoneType;
  CdsId: string;
  Name: string;
  UserZoneId?: string;
  Geometry?: string;
  Areas: IAreaLookupValue[];
  Spaces: ILookupValue[];
  Policies: ILookupValue[];
  PublishedDate: Date;
  LastUpdatedDate: Date;
  StartDate: Date;
  EndDate?: Date;
  StreetName?: string;
  CrossStreetStartName?: string;
  CrossStreetEndName?: string;
  Length?: number;
  Width?: number;
  Median?: boolean;
  EntireRoadway?: boolean;
  StreetSide?: string;
  SpotsCount?: number;
}

export interface IZone {
  Id: number;
  CdsId: string;
  Name: string;
  UserZoneId?: string;
  Geometry?: Geometry;
  Areas: IAreaLookupValue[];
  Spaces: ILookupValue[];
  Policies: ILookupValue[];
  PublishedDate: Date;
  LastUpdatedDate: Date;
  StartDate: Date;
  EndDate?: Date;
  StreetName?: string;
  CrossStreetStartName?: string;
  CrossStreetEndName?: string;
  Length?: number;
  Width?: number;
  Median?: boolean;
  EntireRoadway?: boolean;
  StreetSide?: string;
  ParkingAngle?: string;
}
