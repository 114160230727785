import { FieldRenderProps } from '@progress/kendo-react-form';

import styles from './TextFormField.module.scss';

export const TextFormField = (fieldRenderProps: FieldRenderProps) => {
  const { label, value, onChange, className, ...others } = fieldRenderProps;

  return (
    <div className={`k-form-field ${className}`}>
      {label ? (
        <label htmlFor='#' className='k-label'>
          {label}
        </label>
      ) : null}
      <div className='k-form-field-wrap'>
        <span className={`k-input k-input-md k-rounded-md ${styles.field}`} onChange={onChange}>
          {others?.text ? others?.text : value}
        </span>
      </div>
    </div>
  );
};

export const TextField = (fieldRenderProps: FieldRenderProps) => {
  const { label, value, onChange, ...others } = fieldRenderProps;

  return (
    <span className={`k-input k-input-md k-rounded-md ${styles.field} ${others?.border ? null : styles.noBorder}`}>
      {others?.text ? others?.text : value}
    </span>
  );
};

export const TextLabel = (fieldRenderProps: FieldRenderProps) => {
  const { label, value, onChange, ...others } = fieldRenderProps;

  return (
    <label htmlFor='#' className='k-label'>
      {others?.text ? others?.text : value}
    </label>
  );
};
