import { TimePicker, TimePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';

import { Label, Error, Hint } from '@progress/kendo-react-labels';

export const FormTimespanTimepicker = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, id, valid, disabled, optional, required, hint, value, visited, modified, ...others } =
    fieldRenderProps;

  const showValidationMessage: string | false | null = touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : '';
  const errorId: string = showValidationMessage ? `${id}_error` : '';

  const val = value !== undefined ? parseTimeSpan(value) : null;

  const handleChange = (event: TimePickerChangeEvent) => {
    const timeSpan = event.value ? event.value.toTimeString().split(' ')[0] : undefined;
    const evt = { target: this, value: timeSpan };
    fieldRenderProps.onChange(evt);
    others.onChange && others.onChange(evt);
  };

  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid} editorDisabled={disabled} className={`${required ? 'required' : null}`}>
        {label}
      </Label>
      <div className='k-form-field-wrap'>
        <TimePicker
          ariaDescribedBy={`${hintId} ${errorId}`}
          valid={valid}
          id={id}
          disabled={disabled}
          value={val}
          {...others}
          onChange={handleChange}
        />

        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId} className='error'>
            {validationMessage}
          </Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export function parseTimeSpan(timespan: string | undefined): Date | undefined {
  if (!timespan) {
    return undefined;
  }

  const d = new Date();

  const [hours, minutes, seconds] = timespan.split(':');

  d.setHours(+hours);
  d.setMinutes(+minutes);
  d.setSeconds(+seconds);

  return d;
}
