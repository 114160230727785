import { format, differenceInMinutes, formatISO9075, differenceInSeconds } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { store } from '../store';

const toUiString = (value: Date | null | undefined, showDate = true, showTime = true) => {
  if (!value || (!showDate && !showTime)) {
    return '';
  }

  const datePart = showDate ? format(value, 'MM/dd/yyyy') : '';
  const timePart = showTime ? format(value, 'hh:mm a') : '';
  return `${datePart} ${timePart}`.trim();
};

const toLocalUiString = (value: Date | null | undefined, showDate = true, showTime = true) => {
  if (!value || (!showDate && !showTime)) {
    return '';
  }

  const city = store.getState().cities.selectedCity;
  if (!city) return toUiString(value, showDate, showTime);

  if (typeof value === 'string') value = new Date(value);

  const localDate = utcToZonedTime(value, city.Timezone);
  return toUiString(localDate, showDate, showTime);
};

const toDateString = (value: Date | null  | undefined) => {
  if(!value) { 
    return '' 
  }
  return formatISO9075(value, { representation: 'date' });
};

const toDateTimeString = (value: Date) => formatISO9075(value);

const diffMinutes = (start: Date | null | undefined, end: Date | null | undefined, separator?: string) => {
  if (!start || !end) {
    return '';
  }

  const diff = differenceInMinutes(end, start);
  const hours = Math.floor(diff / 60);
  const minutes = diff % 60;

  const parts: string[] = [];
  if (hours > 0) {
    parts.push(`${hours}h`);
  }

  if (minutes > 0) {
    parts.push(`${minutes}min`);
  }

  if (hours === 0 && minutes === 0) {
    const diffSeconds = differenceInSeconds(end, start);
    parts.push(`${diffSeconds}sec`);
  }

  return parts.join(separator ?? ':');
};

const formatMinutes = (value: number | null | undefined, showSeconds = false, defaultValue = '') => {
  if (!value) {
    return defaultValue;
  }

  const hours = Math.floor(value / 60);
  const minutes = Math.floor(value % 60);
  const seconds = showSeconds ? Math.floor((value * 60) % 60) : 0;
  const hoursString = hours > 0 ? `${hours}h ` : '';
  const minutesString = minutes > 0 ? `${minutes}m ` : '';
  const secondsString = seconds > 0 ? `${seconds}s` : '';
  return (hoursString + minutesString + secondsString).trim();
};

function toHoursString(minutes: number) {
  const remainder = minutes % 60;
  let hours = (minutes - remainder) / 60;
  const suffix = hours < 12 || hours === 24 ? 'AM' : 'PM';
  hours = hours % 12;
  hours = hours !== 0 ? hours : 12; // the hour '0' should be '12'

  return `${hours}:${remainder.toString().padStart(2, '0')}${suffix}`;
}

const parseHoursMinutes = (time: string) => {
  const [h, m] = time.split(':').map((x) => parseInt(x));
  const ms = new Date().setHours(h, m);
  return new Date(ms);
};

const toLocalUiStringHoursMinutes = (date: Date, locales?: Intl.LocalesArgument) => {
  return date.toLocaleTimeString(locales, { hour: '2-digit', minute: '2-digit' });
};

const toTimeZoned = (value: Date, timezone: string) => {
  return utcToZonedTime(value, timezone);
};

export const dateUtils = {
  toUiString,
  toLocalUiString,
  toDateString,
  toDateTimeString,
  diffMinutes,
  formatMinutes,
  toHoursString,
  parseHoursMinutes,
  toLocalUiStringHoursMinutes,
  toTimeZoned,
};
