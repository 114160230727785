import { useRef } from 'react';

import { Checkbox } from '@progress/kendo-react-inputs';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Hint, Label, Error } from '@progress/kendo-react-labels';

export const FormCheckbox = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, touched, label, labelPlacement, id, valid, disabled, hint, wrapperStyle, optional, required, ...others } =
    fieldRenderProps;
  const editorRef = useRef(null);

  const showValidationMessage: string | false | null = touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : '';
  const errorId: string = showValidationMessage ? `${id}_error` : '';
  const labelId: string = label ? `${id}_label` : '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any) => {
    event.value = others.valueMap ? others.valueMap(event.value) : event.value;
    others.onChange(event);
  };

  const valueItem = others.valueMap && others.value ? others.data.find((x: any) => others.valueMap(x) === others.value) : others.value;

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        className={`${required ? 'required' : null}`}
        style={{ minHeight: '22px' }}
      >
        {labelPlacement ? null : label}
      </Label>
      <div
        className={'k-form-field-wrap'}
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', justifyItems: 'center' }}
      >
        <Checkbox
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
          value={valueItem}
          label={labelPlacement ? label : null}
          labelPlacement={labelPlacement}
          onChange={handleChange}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </div>
    </FieldWrapper>
  );
};
