import { useEffect, useRef, useState } from 'react';

import { DropDownList } from '@progress/kendo-react-dropdowns';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Hint, Label, Error } from '@progress/kendo-react-labels';

export const FormDropDown = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    optional,
    required,
    value,
    valueMap,
    data,
    onChange,
    ...others
  } = fieldRenderProps;
  const editorRef = useRef(null);

  const showValidationMessage: string | false | null = touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : '';
  const errorId: string = showValidationMessage ? `${id}_error` : '';
  const labelId: string = label ? `${id}_label` : '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any) => {
    event.value = valueMap && event.value ? valueMap(event.value) : event.value;
    onChange && onChange(event);
  };

  const [valueItem, setValueItem] = useState();
  useEffect(() => {
    const newValue = valueMap && value && typeof value === 'object' ? valueMap(value) : value;

    const newValueItem = valueMap && newValue ? data.find((x: any) => valueMap(x) === newValue) : newValue;
    setValueItem(newValueItem);
  }, [data, value, valueMap]);

  return (
    <FieldWrapper style={wrapperStyle} className={others?.className}>
      {label ? (
        <Label
          id={labelId}
          editorRef={editorRef}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          className={`${required ? 'required' : null}`}
        >
          {label}
        </Label>
      ) : null}
      <div className={'k-form-field-wrap'}>
        <DropDownList
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          data={data}
          {...others}
          value={valueItem}
          onChange={handleChange}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </div>
    </FieldWrapper>
  );
};
