export class CdsTimePeriod {
  private readonly from: number;
  private readonly to: number;

  constructor(from: string | null | undefined, to: string | null | undefined) {
    this.from = this.parseTime(from ? from : '00:00', 0);
    this.to = to ? this.parseTime(to, -1) : this.parseTime('23:59', 59);
  }

  private parseTime(hourMinute: string, seconds: number) {
    const parsed = hourMinute.split(':').map((x) => parseInt(x));
    if (parsed.length >= 2 && parsed[0] >= 0 && parsed[0] <= 23 && parsed[1] >= 0 && parsed[1] <= 59) {
      return seconds + parsed[1] * 60 + parsed[0] * 60 * 60;
    } else {
      throw new Error('Invalid time format (HH:mm)');
    }
  }

  public dateInTimePeriod(dateTime: Date) {
    const time = dateTime.getHours() * 60 * 60 + dateTime.getMinutes() * 60 + dateTime.getSeconds();
    if (this.from <= this.to) {
      return time >= this.from && time <= this.to;
    } else {
      return time >= this.from || time <= this.to;
    }
  }
}
