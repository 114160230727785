import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, CancelToken } from 'axios';

import { acquireToken, getInstance } from '../../features/common/auth/msal-instance';
import { store } from '../../store';
import { Observable, Subscriber } from 'rxjs';
import { apiErrorHandler } from './api-error-handler';
import { getAppSettings } from '..';

const getApiUrl = (): string => {
  return store.getState().appSettings.data?.api_url || '';
};

const getMessagingApiUrl = (): string => {
  return store.getState().appSettings.data?.messaging_api_url || '';
};

const getAssetsApiUrlAsync = (): Promise<string> => {
  return getAppSettings().then((appSettings) => appSettings.assets_api_url);
};

const getCityCode = (): string | undefined => {
  return store.getState().cities.selectedCity?.Code;
};

export function getScopes(): string[] {
  const appSettings = store.getState().appSettings.data;
  if (!appSettings) {
    return [];
  }

  return [`api://${appSettings.auth.aadClientId}/Default`];
}

export function getScopesAsync(): Promise<string[]> {
  return getAppSettings().then((appSettings) => [`api://${appSettings.auth.aadClientId}/Default`]);
}

interface IApiMap {
  [key: string]: AxiosInstance;
}
const _api: IApiMap = {};

interface IApiParameters {
  ignoreTenant?: boolean | undefined;
  ignoreToken?: boolean | undefined;
  ignoreAllErrors?: boolean | undefined;
  ignoreErrorStatuses?: number[];
}

const getAxios = (baseUrl: string, parameters: IApiParameters | null = null): AxiosInstance => {
  const key = `${baseUrl}_${parameters ? JSON.stringify(parameters) : 'default'}`;
  if (_api[key]) {
    return _api[key];
  }

  const api: AxiosInstance = axios.create();

  api.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      config.baseURL = parameters?.ignoreTenant ? baseUrl : `${baseUrl}/${getCityCode()}`;

      if (config.headers && !config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json';
      }

      if (!parameters?.ignoreToken) {
        const accessToken = await acquireToken(await getScopesAsync());
        if (config.headers && accessToken) {
          config.headers['Authorization'] = 'Bearer ' + accessToken;
        }
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  api.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    (err: AxiosError) => {
      if (err.code !== 'ERR_CANCELED') {
        console.log(err);
      }

      if (!parameters?.ignoreAllErrors && !parameters?.ignoreErrorStatuses?.includes(err.response?.status || 0)) {
        apiErrorHandler(err);
        // hanndle case when token is valid for msal but discarded by api (NXCD-2580)
        if (err.response?.status === 401 || err.response?.status === 403) {
          getInstance().then((msal) => msal.logout());
        }
      }

      return Promise.reject(err);
    },
  );

  _api[key] = api;
  return api;
};

export const getApi = (parameters: IApiParameters | null = null) => getAxios(getApiUrl(), parameters);

export const getMessagingApi = (parameters: IApiParameters | null = null) => getAxios(getMessagingApiUrl(), parameters);

export const getAssetsApi = async (parameters: IApiParameters | null = null) => getAxios(await getAssetsApiUrlAsync(), parameters);

export function fromAxiosPromise<T>(func: (token: CancelToken) => Promise<T>): Observable<T> {
  return new Observable((observer: Subscriber<T>) => {
    const cancelToken = axios.CancelToken.source();
    func(cancelToken.token).then(
      (result) => {
        observer.next?.(result);
        observer.complete?.();
      },
      (error) => {
        if (axios.isCancel(error)) {
          observer.complete?.();
        } else {
          observer.error?.(error);
        }
      },
    );

    return () => {
      cancelToken.cancel();
    };
  });
}
