import { Geometry, Point, Position, Polygon } from 'geojson';
import * as pointOnFeature from '@turf/point-on-feature';
import * as midpoint from '@turf/midpoint';
import * as wellknown from 'wellknown';

const findCenter = (geometry?: Geometry) => {
  switch (geometry?.type) {
    case 'Point':
      return geometry;
    case 'LineString':
      return midpoint.default(geometry.coordinates[0], geometry.coordinates[1]).geometry;
    case 'Polygon':
    case 'MultiLineString':
    case 'MultiPolygon':
      return pointOnFeature.default(geometry).geometry;
  }

  throw new Error(`Unknown feature type: ${geometry?.type}`);
};

const toUiString = (value: number[] | null | undefined) => {
  return value ? `${value[0].toFixed(4)}, ${value[1].toFixed(4)}` : null;
};

const toGeometry = (positions: Array<Array<Position>>): Geometry => {
  return positions.length === 1
    ? positions[0].length === 1
      ? {
          type: 'Point',
          coordinates: positions[0][0],
        }
      : {
          type: 'LineString',
          coordinates: positions[0],
        }
    : {
        type: 'MultiLineString',
        coordinates: positions,
      };
};

const toGeometryPointOrPolygon = (positions: Array<Position>): Point | Polygon => {
  return positions.length === 1
    ? {
        type: 'Point',
        coordinates: positions[0],
      }
    : {
        type: 'Polygon',
        coordinates: [positions],
      };
};

const toWKT = (geometry?: Geometry) => {
  return geometry ? wellknown.stringify(geometry as wellknown.GeoJSONGeometry) : null;
};

export const geoUtils = {
  findCenter,
  toUiString,
  toGeometry,
  toGeometryPointOrPolygon,
  toWKT,
};
